import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')
];

export const server_loads = [2,6,3,4,7];

export const dictionary = {
		"/(admin)": [8,[2]],
		"/(admin)/candidates": [~9,[2]],
		"/(auth)/forgot": [27,[6]],
		"/(admin)/jobs": [~10,[2]],
		"/(admin)/jobs/[jobId]": [11,[2,3]],
		"/(admin)/jobs/[jobId]/ask-ai": [~12,[2,3]],
		"/(admin)/jobs/[jobId]/candidates": [~13,[2,3]],
		"/(admin)/jobs/[jobId]/company": [14,[2,3]],
		"/(admin)/jobs/[jobId]/form": [15,[2,3]],
		"/(admin)/jobs/[jobId]/job": [16,[2,3]],
		"/(admin)/onboarding": [17,[2,4]],
		"/(admin)/onboarding/company": [18,[2,4]],
		"/(admin)/onboarding/job": [19,[2,4]],
		"/(admin)/onboarding/marketing": [20,[2,4]],
		"/(admin)/onboarding/team": [~21,[2,4]],
		"/(admin)/profile": [22,[2]],
		"/report/[submissionId]": [~42],
		"/(admin)/settings": [23,[2,5]],
		"/(admin)/settings/billing": [24,[2,5]],
		"/(admin)/settings/company": [25,[2,5]],
		"/(admin)/settings/team": [~26,[2,5]],
		"/(auth)/signin": [28,[6]],
		"/(auth)/signup": [29,[6]],
		"/[jobId]": [30,[7]],
		"/[jobId]/end": [31,[7]],
		"/[jobId]/feedback": [32,[7]],
		"/[jobId]/github": [33,[7]],
		"/[jobId]/interview": [34,[7]],
		"/[jobId]/interview/[questionId]": [~35,[7]],
		"/[jobId]/knockout": [36,[7]],
		"/[jobId]/personal": [37,[7]],
		"/[jobId]/preparation": [38,[7]],
		"/[jobId]/professional": [39,[7]],
		"/[jobId]/selfreview": [40,[7]],
		"/[jobId]/start": [41,[7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';