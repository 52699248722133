import { Replay, BrowserTracing } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { dev } from '$app/environment';
import type { HandleClientError } from '@sveltejs/kit';
import { PUBLIC_SENTRY_DSN } from '$env/static/public';

async function initSentry() {
	// https://github.com/getsentry/sentry-javascript/issues/9252
	if (dev) await new Promise((resolve) => setTimeout(resolve, 1));

	Sentry.init({
		dsn: PUBLIC_SENTRY_DSN,
		environment: dev ? 'development' : 'production',
		tracesSampleRate: dev ? 0 : 1,
		replaysSessionSampleRate: dev ? 0 : 1,
		replaysOnErrorSampleRate: dev ? 0 : 1,
		integrations: [new BrowserTracing(), new Replay()]
	});

	Sentry.setTag('sveltekit', 'browser');
}

initSentry();

export const handleError: HandleClientError = ({ error }: any) => {
	const eventId = Sentry.captureException(error);
	return {
		...error,
		eventId
	};
};
